import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IsAuthenticatedGuard, IsNotAuthenticatedGuard, IsPendingGuard, IsMissingUserInfoGuard, IsExpiredGuard, SelfServiceGuard,  IsTherapyFinishedGuard  } from './@core/guard';
import { Shell } from './shell/shell.service';

const routes: Routes = [
  Shell.childRoutes([
    { path: '', pathMatch: 'full', canActivate: [IsAuthenticatedGuard, SelfServiceGuard], loadChildren: () => import('./modules/patient/home/home.module').then(m => m.HomeModule)},
    { path: 'calendar', canActivate: [IsAuthenticatedGuard], loadChildren: () => import('./modules/patient/calendar/calendar.module').then(m => m.CalendarModule)},
    { path: 'chat', canActivate: [IsAuthenticatedGuard], loadChildren: () => import('./modules/patient/chat/chat.module').then(m => m.ChatModule)},
    { path: 'forms', canActivate: [IsAuthenticatedGuard], loadChildren: () => import('./modules/patient/forms/forms.module').then(m => m.RehubFormsModule)},
    { path: 'help', canActivate: [IsAuthenticatedGuard], loadChildren: () => import('./modules/patient/help/help.module').then(m => m.HelpModule)},
    { path: 'profile', canActivate: [IsAuthenticatedGuard], loadChildren: () => import('./modules/patient/profile/profile.module').then(m => m.ProfileModule)},
    { path: 'evolution', canActivate: [IsAuthenticatedGuard], loadChildren: () => import('./modules/patient/evolution/evolution.module').then(m => m.EvolutionModule)},
    { path: 'self-service', canActivate: [IsAuthenticatedGuard], loadChildren: () => import('./modules/self-service/self-service.module').then(m => m.SelfServiceModule)},
  ]),

  { path: 'welcome', canActivate: [IsAuthenticatedGuard], loadChildren: () => import('./modules/welcome/welcome.module').then(m => m.WelcomeModule)},
  { path: 'exercise', canActivate: [IsAuthenticatedGuard], loadChildren: () => import('./modules/exercise/exercise.module').then(m => m.ExerciseModule)},
  { path: 'terms-and-conditions', canActivate: [IsPendingGuard], loadChildren: () => import('./modules/terms-and-conditions/terms-and-conditions.module').then(m => m.TermsAndConditionsModule)},
  { path: 'update-profile', canActivate: [IsMissingUserInfoGuard], loadChildren: () => import('./modules/update-profile/update-profile.module').then(m => m.UpdateProfileModule)},
  { path: 'expired', canActivate: [IsExpiredGuard], loadChildren: () => import('./modules/expired/expired.module').then(m => m.ExpiredModule)},
  { path: 'therapyFinished', canActivate: [IsTherapyFinishedGuard], loadChildren: () => import('./modules/therapyFinished/therapyFinished.module').then(m => m.TherapyFinishedModule)},
  { path: 'set-password', canActivate: [IsAuthenticatedGuard], loadChildren: () => import('./modules/login/set-password/set-password.module').then(m => m.SetPasswordModule)},
  { path: 'change-password', canActivate: [], loadChildren: () => import('./modules/login/change-password/change-password.module').then(m => m.ChangePasswordModule)},
  { path: 'login', canActivate: [IsNotAuthenticatedGuard], loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule)},
  { path: 'lg', canActivate: [IsNotAuthenticatedGuard], loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule) },
  { path: 'sign-up', loadChildren: () => import('./modules/sign-up/sign-up.module').then(m => m.SignUpModule)},
  { path: 'sign-up-es', loadChildren: () => import('./modules/sign-up/general/es/sign-up-es.module').then(m => m.SignUpEsModule)},
  { path: 'sign-up-fr', loadChildren: () => import('./modules/sign-up/general/fr/sign-up-fr.module').then(m => m.SignUpFrModule)},
  { path: 'sign-up-general', loadChildren: () => import('./modules/sign-up/general/sign-up-general.module').then(m => m.SignUpGeneralModule)},
  { path: 'pharma-form', canActivate: [IsAuthenticatedGuard], loadChildren: () => import('./modules/pharma-form/pharma-form.module').then(m => m.PharmaFormModule)},
  { path: 'remote-controller', canActivate: [IsAuthenticatedGuard], loadChildren: () => import('./modules/remote-controller/remote-controller.module').then(m => m.RemoteControllereModule)},
  { path: 'connection', canActivate: [], loadChildren: () => import('./modules/connection/connection.module').then(m => m.ConnectionModule)},
  // { path: 'public', canActivate: [], loadChildren: () => import('./modules/public/public.module').then(m => m.PublicModule)},
  { path: 'assets', redirectTo: '', pathMatch: 'full' },
  { path: '**', redirectTo: '/login', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
