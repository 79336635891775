<header class="fixed-top rehub-nav-header  shadow  rehub-header " *ngIf="!isWellwo">

  <nav class="d-flex flex-row align-items-center h-100 navbar navbar-dark navbar-expand-xl p-0 " [ngClass]="{'px-3': !isMobile, 'px-2': isMobile}">
    <a class="navbar-brand text-primary d-flex flex-row align-items-center justify-content-center" (click)="hideMenu()"
      [routerLink]="isSelfService() ? '/self-service' : '/'" style="gap: 5px;">

      <ng-container *ngIf="!isCustomLogo">
        <ng-container *ngIf="!isSelfService()">
          <img _ngcontent-c0="" alt="Rehub" src="assets/images/rehub_logo_icon.png" style=" height: 30px;">
          <!-- <rehub-icon name="rehub" size="25"></rehub-icon>
          <h2 class="m-0 color-black">ReHub</h2> -->
        </ng-container>
        <ng-container *ngIf="isSelfService()">
          <h2 class="m-0">VitalHub</h2>
        </ng-container>
      </ng-container>

      <img *ngIf="isCustomLogo" [src]="customLogo" class="rehub-custom-icon">
    </a>


    <div class="d-flex flex-row align-items-center">
      <div *ngIf="!isCoachHidden" class="d-xl-none nav-item d-flex flex-column justify-content-center mr-2">
        <img (click)="toggleCoach()" class="pointer" style="height: 40px;" [style.opacity]="isCoachDisabled ? '0.3' : '1.0'" data-toggle="tooltip" data-placement="bottom" [title]="'general_toggle_coach' | translate" src="/assets/images/coach/coach_head_small.png">
      </div>
      <div class="d-xl-none nav-item d-flex flex-column justify-content-center mr-2" *ngIf="measurementProgramService.isEnabled()">
        <a class="cursor-pointer" data-placement="bottom" [ngClass]="getRealtimeStatusColor()" data-toggle="tooltip"
          data-placement="top" title="{{'sensor_settings_title_state_' + getRealtimeStatusColor() | translate}}">
          <rehub-icon name="connection" size="40"></rehub-icon>
        </a>
      </div>
      <rehub-icon name="menu" class="d-xl-none text-primary cursor-pointer" (click)="toggleMenu($event)" *ngIf="!(isSelfServices && isAssegur) && !isMenuDisabled"></rehub-icon>
    </div>

    <!-- Desktop -->
    <div class=" p-2 d-none d-xl-block w-100">

      <div class="d-flex flex-row w-100 m-0" style="max-width: 800px; gap: 35px;">

        <ng-container *ngFor="let menuItem of menuItems">

          <ng-container *ngIf="!menuItem.separator && !menuItem.options.hidden && menuItem.show">

            <a *ngIf="!menuItem.options.onlyMobile" [routerLink]="menuItem.route" routerLinkActive="header-active" [routerLinkActiveOptions]="menuItem.options" style="opacity: 0.5;"
              class="d-flex ml-1 align-items-center cursor-pointer text-uppercase text-primary font-weight-bold text-decoration-none position-relative">
              <rehub-icon [attr.name]="menuItem.icon" size="20"></rehub-icon>
              <span class="ml-2">{{menuItem.key | translate}}</span>
              <rehub-icon name="shape_circle" size="10" class="new-notication" *ngIf="hasNewMessage && menuItem.key == 'navigation_chat'"></rehub-icon>
              <!-- <rehub-icon name="shape_circle" size="10" class="d-none" [routerLinkActiveOptions]="menuItem.options" routerLinkActive="d-flex"></rehub-icon> -->
            </a>

          </ng-container>

        </ng-container>
      </div>

    </div>

    <!-- Mobile -->

    <div class=" text-primary rehub-nav p-2 d-none d-block d-xl-none bg-white card-rounded mx-2 shadow-lg" (clickOutside)="hideMenu()" *ngIf="!menuHidden && !isMenuDisabled">

      <div class="d-flex flex-column list-unstyled m-0" style="font-size: 12px;">

        <ng-container *ngFor="let menuItem of menuItems">
          <ng-container *ngIf="!menuItem.options.hidden && !menuItem.onlyDesktop">
            <a (click)="hideMenu()" class="dropdown-item d-flex text-primary active-item align-items-center" [routerLink]="menuItem.route" routerLinkActive="header-active" [routerLinkActiveOptions]="menuItem.options">
              <rehub-icon [attr.name]="menuItem.icon"size="25" class="mr-2"></rehub-icon>
              {{menuItem.options.onlyDesktop}}
              {{menuItem.title | translate}}
            </a>
          </ng-container>

        </ng-container>

        <ng-container *ngIf="featureFlagsService.showLogoutPatient || !isAssegur">
          <hr class="bg-white bg-white w-100 my-2 d-xl-none" style="opacity: 0.5;">
          <a class="dropdown-item d-flex text-primary" (click)="logout()" *ngIf="!isAssegur">
            <rehub-icon name="logout_1" size="20" class="mr-2 "></rehub-icon>
            {{'general_logout' | translate}}
          </a>
        </ng-container>

      </div>

    </div>

    <div class="nav-item flex-column justify-content-center mr-2 d-none  d-xl-block" *ngIf="!isMobile && !isCoachHidden">
      <img (click)="toggleCoach()" class="pointer" style="height: 40px;" [style.opacity]="isCoachDisabled ? '0.3' : '1.0'" data-toggle="tooltip" data-placement="bottom" [title]="'general_toggle_coach' | translate" src="/assets/images/coach/coach_head_small.png">
    </div>

    <div class="nav-item d-flex flex-column justify-content-center mr-2" *ngIf="measurementProgramService.isEnabled()">
      <a class="cursor-pointer" data-placement="bottom" [ngClass]="getRealtimeStatusColor()" data-toggle="tooltip"
        data-placement="top" title="{{'sensor_settings_title_state_' + getRealtimeStatusColor() | translate}}">
        <rehub-icon name="connection" size="40"></rehub-icon>
      </a>
    </div>


    <a *ngIf="voiceCredentials" class="cursor-pointer" (click)="toggleMicro()" style="margin-right:5px" >
      <rehub-icon name="micro" size="40" *ngIf="!isMicroDisabled" style="color:yellowgreen"></rehub-icon>
      <rehub-icon name="micro_off" size="40" *ngIf="isMicroDisabled" style="color: #80c4cc"></rehub-icon>
    </a>


    <div class="d-none d-xl-block ml-2" *ngIf="!(isSelfServices && isAssegur)">
      <ul class="navbar-nav mr-auto align-items-center">
        <div class="d-none d-xl-flex dropdown align-items-center" style="min-width: 200px; cursor: pointer;">
          <ng-container >
            <div class="d-none d-xl-flex dropdown align-items-center" data-toggle="dropdown">
              <rehub-avatar [image]="profileSettings.image" [name]="profileSettings.alias" ></rehub-avatar>
              <div class="d-flex  justify-content-center ml-2 text-primary" >
                <h4 class="m-0">{{profileSettings.name}}</h4>
                <rehub-icon name="back_to" size="20" rotate="270"  class="mr-2 mr-2  mt-1" *ngIf="!isInFullScreen()"></rehub-icon>
              </div>
            </div>
            <ng-container *ngTemplateOutlet="dropDownRC"></ng-container>
          </ng-container>
        </div>
      </ul>
    </div>
  </nav>

</header>

<ng-template #dropDownRC>
  <div class="dropdown-menu bg-white" *ngIf="!isMenuDisabled">
    <a class="dropdown-item d-flex text-primary" (click)="onAvatarClick()" *ngIf="!isSelfServices && !isAssegur">
      <rehub-icon name="profile" size="20" class="mr-2 mr-2  mt-1"></rehub-icon>
      {{'general_perfil' | translate}}</a>
    <a class="dropdown-item d-flex text-primary" (click)="onHelpPressed()">
      <rehub-icon name="helper" size="20" class="mr-2 mr-2  mt-1"></rehub-icon>
      {{'help_header' | translate}}
    </a>
    <a class="text-primary dropdown-item cursor-pointer full-screen-chk d-flex" data-toggle="tooltip"
      data-placement="bottom" (click)="toggleFullScreen()" *ngIf="!isAssegur">
      <rehub-icon name="fullscreen_disable" size="20"  class="mr-2 mr-2  mt-1" *ngIf="isInFullScreen()"></rehub-icon>
      <rehub-icon name="fullscreen_enable" size="20"  class="mr-2 mr-2  mt-1" *ngIf="!isInFullScreen()"></rehub-icon>
      {{'profile_toggle_full_screen' | translate}}
    </a>
    <a class="dropdown-item d-flex text-primary" (click)="logout()" *ngIf="!isAssegur">
      <rehub-icon name="logout_1" size="20" class="mr-2 mr-2  mt-1"></rehub-icon>
      {{'general_logout' | translate}}
    </a>

  </div>
</ng-template>

<div *ngIf="loadingChromecast">
  <div class="background"></div>

  <div class="align-items-center content d-flex flex-column h-100 justify-content-center m-auto">
    <rehub-spinner size="7"></rehub-spinner>
    <h1 class="text-white">{{'general_connecting' | translate}}</h1>
  </div>
</div>
