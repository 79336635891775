import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CredentialsService } from '@core/auth/credentials.service';
import { EventBusService, ToastService } from '@core/service';
import { Router } from '@angular/router';

import * as moment from 'moment';
import { Utils } from '@app/utils';
import { SharedUtils } from '@rehub-shared/utils/utils';
import { environment } from '@env/environment';
import { Permission } from '@rehub-shared/utils/permissions';
import { I18nService } from '@app/i18n';
import { ChatAPI, FileAPI } from "@rehub-shared/api"
import { FileUtils } from "@rehub-shared/utils"
import { CoachService } from '@app/@core/service/coach.service';
import { SpeechRecognition } from '@app/@shared/chat/speechrecognition.service';
import { IOSExerciseService } from '@rehub-shared/measurement-program/ios-exercise.service';
import { ChatUtils } from '@rehub-shared/utils/chat.utils';


@Component({
	selector: 'rehub-chat',
	templateUrl: './chat.component.html',
	styleUrls: ['./chat.component.scss'],
  host: { class: 'cy-chat' },
})
export class ChatComponent implements OnInit {

  Utils = Utils;
  SharedUtils = SharedUtils;

  @Input() id: string;

  filteredChats :any;
  filterText:string;
  userTypes = ["professionals", "patients"];
  reloadIntervalTime = 15 * 1000;
  userId: string;
  chats :any;
  reloadIntervalReference :any;
  isMessage: boolean = false;
  isLoading: boolean = true;
  disableSend: boolean = false;
  selectedUser: any;
  message: string;
  items: any[] = [];
  scrolled: boolean = false;

  selectedChatGroup: any;
  notificationPatientReponse: any;

  permissionFileUpload: boolean = false;

  isMobile: boolean = false;
  position: 'mobile' | 'desktop' = 'desktop';


  @ViewChild('newMessage') newMessageInput!: ElementRef;

  constructor(
    private credentialsService: CredentialsService,
    private router: Router,
    private toastService: ToastService,
    private i18nService: I18nService,
    private eventBus: EventBusService,
    private coachService: CoachService,
    public speechRecognitionService: SpeechRecognition
  ) {}

	ngOnInit() {
    this.permissionFileUpload = this.credentialsService.checkPermission(Permission.File_Upload);

    this.userId = this.credentialsService.getCredentials().sub;
    this.loadChat();
    this.notificationPatientReponse = this.eventBus.subscribe("WS_NEW_CHAT_MESSAGE", (data: any) => {
      this.clickChatGroup(this.selectedChatGroup);
    });

    // Subscribe to transcription updates
    this.speechRecognitionService.getTranscriptionObservable().subscribe((transcription: string) => {
      this.message = transcription;
    });

    this.position = Utils.isMobile() ? 'mobile' : 'desktop';

  }

  toggleListening() {
    this.speechRecognitionService.toggleListening();
  }

  startListening() {
    this.speechRecognitionService.startListening();
  }

  stopListening() {
    this.speechRecognitionService.stopListening();
  }
  onInputMessage(event: any) {
    const inputElement = event.target as HTMLTextAreaElement;
    this.message = inputElement.value;
  }
  ngAfterViewChecked() {
    if (!this.scrolled) {
      this.scrollChatToBottom();
    }
  }

  ngOnDestroy() {
    if (this.reloadIntervalReference) {
      clearInterval(this.reloadIntervalReference);
      this.reloadIntervalReference = null;
    }
    if (this.notificationPatientReponse) this.notificationPatientReponse.unsubscribe();
  }

  getAvatar(item: any) {
    return SharedUtils.getAvatarUrl(item.user.member, environment.output_s3_url);
  }

  clickChatGroup(chatGroup:any, startCall:boolean = false) {
    if (this.reloadIntervalReference) clearInterval(this.reloadIntervalReference);
    this.selectedChatGroup = chatGroup;

    let id = chatGroup.memberId == this.userId ? chatGroup.ownerId : chatGroup.memberId;
    this.updateUrlId(id);

    this.loadChatGroup(chatGroup, startCall);

    this.reloadIntervalReference = setInterval(() => {
      if(this.reloadIntervalReference) this.loadChatGroup(chatGroup);
    }, this.reloadIntervalTime);
  }

  updateUrlId(id: string) {
    let url = "/chat";
    this.router.navigate([url], {queryParams: {id: id}, replaceUrl: true});
  }

  getCallStatus(message:any) {
    let status;
    if (message.END_CALL) {
      status = "END_CALL";
    } else if (message.REFUSE_CALL) {
      status = "REFUSE_CALL";
    } else if (message.JOIN_CALL) {
      status = "JOIN_CALL";
    } else if (message.ACCEPT_CALL) {
      status = "ACCEPT_CALL";
    } else if (message.START_CALL) {
      status = "START_CALL";
    }
    return status;
  }

  loadChatGroup(chatGroup: any, startCall:boolean = false) {
    ChatAPI.getChatGroupMessages(chatGroup.groupId).then((result: any) => {
      if (chatGroup.newMessage) {
        // this.notificationService.checkNotifications();
      }
      chatGroup.newMessage = false;
      result.forEach((chatMessage: any) => {
        chatMessage.sender = chatMessage.ownerId == this.userId ? "me" : "other";
      });

      this.selectedChatGroup.messages = result;
      this.isMessage = this.selectedChatGroup.messages.length > 0
      this.isLoading = false;

      let startedCalls = this.selectedChatGroup.messages.filter((callMsn: any) => this.getCallStatus(callMsn) == "START_CALL");
      let acceptedCalls = this.selectedChatGroup.messages.filter((callMsn: any) => this.getCallStatus(callMsn) == "ACCEPT_CALL");
      let joinedCalls = this.selectedChatGroup.messages.filter((callMsn: any) => this.getCallStatus(callMsn) == "JOIN_CALL");
      let endedCalls = this.selectedChatGroup.messages.filter((callMsn: any) => this.getCallStatus(callMsn) == "END_CALL");
      let refusedCalls = this.selectedChatGroup.messages.filter((callMsn: any) => this.getCallStatus(callMsn) == "REFUSE_CALL");
      if (startedCalls) {
        startedCalls.forEach((el: any) => {
          el.note = this.i18nService.translate("videocall_message_receiver_START_CALL");
          el.isCall = true;
        });
      }
      if (acceptedCalls) {
        acceptedCalls.forEach((el: any) => {
          el.note = this.i18nService.translate("videocall_message_receiver_ACCEPT_CALL");
          el.isCall = true;
        });
      }
      if (joinedCalls) {
        joinedCalls.forEach((el: any) => {
          el.note = this.i18nService.translate("videocall_message_receiver_JOIN_CALL");
          el.isCall = true;
        });
      }
      if (endedCalls) {
        endedCalls.forEach((el: any) => {
          el.note = this.i18nService.translate("videocall_message_receiver_END_CALL");
        });
      }
      if (refusedCalls) {
        refusedCalls.forEach((el: any) => {
          el.note = this.i18nService.translate("videocall_message_receiver_REFUSE_CALL");
        });
      }

      if (startCall) this._sendCallStatusMessage("START_CALL");
    }, (error: any) => {
      if (error.code == "ECONNABORTED") {
        this.toastService.show(this.i18nService.translate('general_connection_error'), 'danger', false, this.position);
      } else if (error.status == 0) {
        // this.modal.showNoInternet();
      } else {
        this.toastService.show(this.i18nService.translate('general_form_error'), 'danger', false, this.position);
      }
      console.error("ERROR TO LOAD CHAT GROUP", error);
    });
  }

  changeUser(itemSelected: any) {
    let item:any = this.items.find((item: any) => item.user && item.user.member.selected);
    if (item.user.member.userId == itemSelected.user.member.userId) return;
    item.user.member.selected = false;
    item = this.items.find((item:any) => item.user && item.user.member.userId == itemSelected.user.member.userId);
    item.user.member.selected = true;
    this.selectedUser = item.user.member.userId;
    this.clickChatGroup(item.user);
  }

  onUserSelected(userId: any) {
    let item:any = this.items.find((item:any) => item.user && item.user.member.userId == userId);
    this.changeUser(item);
  }

  onSendMessagePressed() {
    //TODO! trim antes de comprobar si es válido
    if (this.disableSend || !this.isValidMessage(this.message)) return;

    let m: any = {
      "read": false,
      "createDate": moment(),
      "note": this.message.trim(),
      "sender": "me"
    };

    this.selectedChatGroup.messages.push(m);
    this.isMessage = this.selectedChatGroup.messages.length > 0;
    this.disableSend = true;

    ChatAPI.postMessageToChatGroup(this.selectedChatGroup.groupId, this.message.trim()).then((result: any) => {
      setTimeout(() => {
        this.disableSend = false;
        this.message = "";
      }, 2000);
      this.scrollChatToBottom();
    }, (error: any) => {
      if (error.code == "ECONNABORTED") {
        // this.snackbar.showError("general_connection_error", null, this.isPatient());
        this.toastService.show(this.i18nService.translate('general_connection_error'), 'danger', false, this.position);
      } else if (error.status == 0) {
        // this.modal.showNoInternet();
      } else {
        // this.snackbar.showWarning("dialog_error_post_message", null, this.isPatient());
        this.toastService.show(this.i18nService.translate('dialog_error_post_message'), 'danger', false, this.position);
      }
      this.disableSend = false;
      console.log("ERROR SEND CHAT MESSAGE", error);
    });
  }

  onFileChange(event: any) {
    FileUtils.readFileEvent(event, (file: any, name: string, extension: string, size: number) => {
      if (this.isValidFile(name, size)) {
        let folderName = this.selectedChatGroup.groupId;
        let folderType = "CHAT";

        event.srcElement.value = null;

        this.uploadFileToS3(file, name + extension, size, folderName, folderType);
      } else {
        if (!FileUtils.isValidSize(size)) this.toastService.show(this.i18nService.translate("general_file_error_size"), 'danger', false, this.position);
        else if (!FileUtils.isValidName(name)) this.toastService.show(this.i18nService.translate("general_file_error_invalid_name"), 'danger', false, this.position);
      }
    });
  }

  isValidFile(name: string, size: number) {
    return FileUtils.isValidName(name) && FileUtils.isValidSize(size);
  }

  isValidMessage(message: string){
    message = message.trim();
    return message.length > 0 && message.length <= 500;
  }

  isSelfService(){
    return this.credentialsService.isSelfService();
  }

  uploadFileToS3(fileData: any, name: string, size: number, folderName: string, folderType: string) {
    fetch(fileData).then((response: any) => response.blob()).then((blob: any) => {
      this.toastService.show(this.i18nService.translate("general_file_uploading"), 'warning', false, this.position);

      FileAPI.uploadFile(this.userId, blob, name, folderName, folderType).then((fileKey: any) => {
        this.toastService.show(this.i18nService.translate("general_file_uploaded"), 'warning', false, this.position);

        let note = name + "*_*" + size;

        ChatAPI.postMessageToChatGroup(this.selectedChatGroup.groupId, note, null, fileKey).then((result: any) => {
          let m: any = {
            "fileUrl": "",
            "createDate": moment(),
            "note": note,
            "sender": "me"
          };

          this.selectedChatGroup.messages.push(m);
          this.scrollChatToBottom();
        });
      });
    });
  }

  onDownloadFilePressed(url: string) {
    if (!url || url == "") return;
    window.location.href = url;
  }

  acceptIncomingCall(sortKey:any) {
    this._sendCallStatusMessage("ACCEPT_CALL", sortKey);
    this.goToVideoCallRoom(sortKey);
  }

  joinCall(sortKey:any) {
    this._sendCallStatusMessage("JOIN_CALL", sortKey);
    this.goToVideoCallRoom(sortKey);
  }

  refuseIncommingCall(sortKey:any) {
    this._sendCallStatusMessage("REFUSE_CALL", sortKey);
    this.loadChat();
  }

  refuseCallNotification(sortKey:any) {
    this._sendCallStatusMessage("REFUSE_CALL", sortKey);
    this.loadChat();
  }

  goToVideoCallRoom(roomId: any) {
    this.coachService.removeFromDOM();

    let params = {
      ownerId: this.userId,
      title: roomId,
      groupId: this.selectedChatGroup.groupId
    };

    let videoCallURL = ChatUtils.getVideoCallUrl(environment.patient_url, params);


    if(SharedUtils.isIOS() && IOSExerciseService.hasInterface()){
      window.location.assign(videoCallURL);
    } else {
    window.open(videoCallURL, "_blank");
    }
  }

  getFileNameFromMessage(message: string) {
    return message.split("*_*")[0];
  }

  getFileSizeFromMessage(message: string) {
    let size = parseInt(message.split("*_*")[1]);
    return !Number.isNaN(size) ? FileUtils.formatSize(size) : "-";
  }

  private loadChat() {
    ChatAPI.getChatGroups().then((result: any) => {
      // Sort chat groups by last message
      result.sort((a: any, b: any) => {
        var valueA = a.lastMessage || "-1";
        var valueB = b.lastMessage || "-1";

        if (valueA > valueB) return -1;
        else if (valueA < valueB) return 1;
        else return 0;
      });


      // result.forEach((chat: any)=>{
      //   if(chat.lastMessage) chat.date = chat.lastMessage.split("_")[0];
      // });

      this.items = [];

      this.chats = result;
      this.filteredChats = {};

      // Separate list into professionals and patients
      this.filterName("");

      let selected;
      if (this.filteredChats.patients.length) selected = this.filteredChats.patients[0];
      if (this.filteredChats.professionals.length) selected = this.filteredChats.professionals[0];
      if (this.id) {
        let found;
        if (SharedUtils.isIdPatient(this.id)) found = this.filteredChats.patients.find((p: any)=>p.memberId == this.id);
        else if (SharedUtils.isIdUser(this.id)) found = this.filteredChats.professionals.find((p: any)=>p.memberId == this.id);
        if (found) {
          selected = found;
          this.filterName(selected.member.name);
        }
      }
      this.addItems(selected);
      this.clickChatGroup(selected);
    }, (error: any) => {
      if (error.code == "ECONNABORTED") {
        this.toastService.show(this.i18nService.translate('general_connection_error'), 'danger', false, this.position);
      } else if (error.status == 0) {
        // this.modal.showNoInternet();
      } else {
        // this.snackbar.showWarning("general_form_error", null, this.isPatient());
        this.toastService.show(this.i18nService.translate('general_form_error'), 'danger', false, this.position);
      }
      console.error("ERROR LOAD CHAT", error);
    });
  }

  private filterName(name: string) {
    this.filteredChats.patients = this.chats.filter((m: any) => m.member && (SharedUtils.isIdPatient(m.memberId)) ?
      m.member.name.toLowerCase().includes(name.toLowerCase()) : false);
    this.filteredChats.professionals = this.chats.filter((m: any) => m.member && (SharedUtils.isIdUser(m.memberId)) ?
      m.member.name.toLowerCase().includes(name.toLowerCase()) : false);
    this.filterText = name;
  }

  private scrollChatToBottom() {
    let messagesContainerElement = document.querySelector("#messagesContainer");

    if (messagesContainerElement) {
      messagesContainerElement.scrollIntoView({ behavior: "auto", block: "end" });
      messagesContainerElement.scrollTop = messagesContainerElement.scrollHeight;
      this.scrolled = true;
    }
  }

  private addItems(selected: any) {
    this.userTypes.forEach((userType: any) => {
      if (this.filteredChats && this.filteredChats[userType] && this.filteredChats[userType].length) {
        this.items.push({type: 'group', group: {name: this.i18nService.translate('chat_professionals')}});
        this.filteredChats[userType].forEach((patients:any) => {
          if (patients.memberId == selected.memberId) {
            patients.member.selected = true;
            this.selectedUser = patients.member.userId;
          }
          this.items.push({type: 'user', user: patients});
        });
      }
    });
  }

  private _sendCallStatusMessage(callStatus: string, sortKey:string = null) {
    let m: any = {
      "read": false,
      "createDate": moment(),
      "sender": "me",
      "note": this.i18nService.translate("videocall_message_caller_START_CALL"),
      "START_CALL": true
    };

    m[callStatus] = true;

    let item = {
      "callStatus": callStatus
    };

    if (sortKey) item['sortKey'] = sortKey;
    if (callStatus == "START_CALL") this.selectedChatGroup.messages.push(m);

    this.disableSend = true;

    ChatAPI.postCallStatusToChatGroup(this.selectedChatGroup.groupId, item).then((result: any) => {
      setTimeout(() => {
        this.disableSend = false;
      }, 2000);
    }, (error: any) => {
      if (error.code == "ECONNABORTED") {
        //this.snackbar.showError("general_connection_error", null, this.isPatient());
      } else if (error.status == 0) {
        //this.modal.showNoInternet();
      } else {
        //this.snackbar.showWarning("dialog_error_post_message", null, this.isPatient());
      }
      this.disableSend = false;
      console.log("ERROR SEND CHAT MESSAGE", error);
    });
  }

}