import { API } from "./api";

export class ProtocolAPI extends API {

  static getProtocolFromSlug(slug: string){
    let useCache = !slug.startsWith("CUSTOM_");
    const url = `${this.getBaseUrl()}/protocol/from_slug/${slug}`;
    return this.get(url, {}, {timeout: 20000, cache: useCache});
  }

  static getProtocols(owners: ("REHUB" | "USER" | "CENTER")[] = [], cache: boolean = false){
    const url = `${this.getBaseUrl()}/protocol/all`;
    return this.get(url, {owners}, {cache: cache});
  }

  static getProtocolsRehub(){
    return this.getProtocols(["REHUB"], true);
  }

  static getProtocolsUser(){
    return this.getProtocols(["USER"]);
  }

  static getProtocolsCenter(){
    return this.getProtocols(["CENTER"]);
  }

  static getProtocolGroups(owners: ("REHUB" | "USER" | "CENTER")[] = [], cache: boolean = false){
    let url = `${this.getBaseUrl()}/protocol/groups`;
    return this.get(url, {owners}, {timeout: 20000, cache: cache});
  }

  static getProtocolGroupsRehub(){
    return this.getProtocolGroups(["REHUB"], true);
  }

  static getProtocolGroupsUser(){
    return this.getProtocolGroups(["USER"]);
  }

  static getProtocolGroupsCenter(){
    return this.getProtocolGroups(["CENTER"]);
  }

  static getAssignedProtocols(patientId: string){
    const url = `${this.getBaseUrl()}/protocol/from_patient/${patientId}`;
    return this.get(url, {});
  }

  static getLynxProtocols(joint: string | null = null){
    if(joint == null){
      const url = `${this.getBaseUrl()}/protocol/all/lynx`;
      return this.get(url, {});
    }else{
      const url = `${this.getBaseUrl()}/protocol/from_joint/lynx`;
      const data = {joint: joint}

      return this.post(url, data);
    }
  }

  static getLynxProtocolFromSlug(slug: string){
    const url = `${this.getBaseUrl()}/protocol/from_slug/${slug}/lynx`;
    return this.get(url, {});
  }

  static updateProtocol(protocol: any){
    const url = `${this.getBaseUrl()}/protocol/custom/update`;
    return this.post(url, protocol);
  }

  static createProtocol(protocol: any){
    const url = `${this.getBaseUrl()}/protocol/custom/create`;
    return this.post(url, protocol);
  }

  static createProtocolManual(protocol: any){
    const url = `${this.getBaseUrl()}/protocol/custom/manual/create`;
    return this.post(url, protocol);
  }

  static deleteProtocol(protocolId: any){
    const url = `${this.getBaseUrl()}/protocol/custom/delete/${protocolId}`;
    return this.post(url, {});
  }

  static getProtocolGroup(groupId: string){
    const url = `${this.getBaseUrl()}/protocol/group/${groupId}`;
    return this.get(url, {}, {timeout: 20000});
  }

  static createProtocolGroup(params: any){
    const url = `${this.getBaseUrl()}/protocol/group/create`;
    return this.post(url, params, {timeout: 20000});
  }

  static deleteProtocolGroup(groupId: string){
    const url = `${this.getBaseUrl()}/protocol/group/${groupId}/delete`;
    return this.post(url, {});
  }

  static updateProtocolGroup(params: any){
    const url = `${this.getBaseUrl()}/protocol/group/${params.groupId}/update`;
    return this.post(url, params);
  }

  static updateProtocolGroupProtocols(groupId: any, protocols: any){
    const url = `${this.getBaseUrl()}/protocol/group/${groupId}/protocols/update`;
    return this.post(url, protocols);
  }

  static getRecomendedProtocols(params: any){
    const url = `${this.getBaseUrl()}/protocol/tests`;
    return this.post(url, params);
  }
}