import {default as defaultEnvironment} from './environment.default';

const aws_info = {
  'aws_app_analytics': 'enable',
  'aws_cognito_identity_pool_id': 'eu-west-1:e0529703-4905-4165-b156-e358033cf16f',
  'aws_cognito_region': 'eu-west-1',
  'aws_mobile_analytics_app_id': 'f1d2c857f56745719d84a6c6082a8a09',
  'aws_mobile_analytics_app_region': 'us-east-1',
  'aws_project_id': '129c1b85-7ae1-4cc1-b688-89245e75f667',
  'aws_project_name': 'RehubAndroidClient-Test',
  'aws_project_region': 'eu-west-1',
  'aws_resource_name_prefix': 'rehubandroidclientte-mobilehub-1189428210',
  'aws_sign_in_enabled': 'enable',
  'aws_user_pools': 'enable',
  'aws_user_pools_id': 'eu-west-1_ARhqZ91zP',
  'aws_user_pools_web_client_id': '193ap58v79uadh9v237vh549g5',
}

export const environment = {
  ...defaultEnvironment,
  production: true,
  hmr: false,
  stage: "test2",
  bucket: "s3://patient-test2.rehub.cloud",
  manifest_path: "/test2",
  manifest_version: "v1.0.3",
  client_login: "rehub_test2",
  professional_url: "https://test.rehub.cloud",
  url: "https://patient-test.rehub.cloud",
  patient_url: "https://patient-test.rehub.cloud",
  exercise_url: "https://patient-test.rehub.cloud/exercises",
  gtm_code: "GTM-TQ9NN3H",
  center_VITALHUB: '',
  supportedLanguages: [
    {name: "english", code: "en", flag: "gb"},
    {name: "spanish", code: "es", flag: "es"},
    {name: "italian", code: "it", flag: "it"},
    {name: "catalan", code: "ca", flag: "ca"},
    {name: "french", code: "fr", flag: "fr"},
    {name: "german", code: "de", flag: "de"},
    {name: "portuguese", code: "pt", flag: "pt"},
    {name: "dutch", code: "nl", flag: "nl"},
    {name: "spanish-mexico", code: "esMX", flag: "esMX"}
  ],


  patient_server_url:     'https://n63g5t5ez8.execute-api.eu-west-1.amazonaws.com/test2',
  records_server_url:     'https://r07fr5vqif.execute-api.eu-west-1.amazonaws.com/test2',
  therapy_server_url:     'https://pexwuseuze.execute-api.eu-west-1.amazonaws.com/test2',
  user_server_url:        'https://zg691r1oxa.execute-api.eu-west-1.amazonaws.com/test2',
  message_server_url:     'https://xdag8nnd06.execute-api.eu-west-1.amazonaws.com/test2',
  protocol_server_url:    'https://a4od9wc4v9.execute-api.eu-west-1.amazonaws.com/test2',
  sensor_server_url:      'https://zd028pxnkb.execute-api.eu-west-1.amazonaws.com/test2',
  file_server_url:        'https://ubx52cq0e7.execute-api.eu-west-1.amazonaws.com/test2',
  notification_server_url:       'https://bgddbwvoni.execute-api.eu-west-1.amazonaws.com/test2',
  message_ws_server_url:  'wss://50da62ahj3.execute-api.eu-west-1.amazonaws.com/test2',
  notif_ws_server_url:    'wss://qvt7h79k9l.execute-api.eu-west-1.amazonaws.com/test2',
  output_s3_url:          'https://patient-test.rehub.cloud/',
  login_url_backend:      'https://7lcvp3picb.execute-api.eu-west-1.amazonaws.com/dev',
  login_url:              'https://login-site.dycare-development.com',
  realtime_ip:            'https://realtime.dycare.com:4200',
  aws_info: aws_info,

};